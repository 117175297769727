<template>
  <SkeletonBase :height="height" :theme="theme" base-class="round">
    <div slot-scope="{ classes }" :class="classes">
      <!-- rectangle -->
    </div>
  </SkeletonBase>
</template>
<script>
import SkeletonBase from './skeleton-base';
import props from './props';

export default {
  name: 'Round',
  components: {
    SkeletonBase,
  },
  mixins: [props],
};
</script>
